import React from "react";
import { Form, Input, TextArea, Dropdown } from "semantic-ui-react";

export default (props) => {
  const { showLabels, field, value, error, onChange, disabled } = props;
  const {
    label,
    placeholder,
    type,
    required,
    options,
    inputType,
    labelDisplay,
    infosText,
  } = field;
  const labelToDisplay = showLabels
    ? labelDisplay.slice(0, 1).toUpperCase() + labelDisplay.slice(1)
    : "";

  if (type === "hidden") {
    // No UI element.
    return null;
  }

  if (type === "select") {
    const updatedOptions = options.map((option) => {
      return {
        key: option.value,
        value: option.value,
        text: option.label,
      };
    });

    return (
      <div style={{ margin: "0 0 1em 0", textAlign: "left" }}>
        <p style={{ margin: "1.3em 0 0 0", textAlign: "left" }}>{infosText}</p>
        <Form.Select
          disabled={disabled}
          error={error}
          required={required}
          key={label}
          name={label}
          label={labelToDisplay}
          placeholder={placeholder}
          value={value}
          labeled={false}
          options={updatedOptions}
          onChange={onChange}
        />
      </div>
    );
  }

  if (type === "multiSelect") {
    const updatedOptions = options.map((option) => {
      return {
        key: option.value,
        value: option.value,
        text: option.label,
      };
    });

    return (
      <div style={{ margin: "0 0 1em 0", textAlign: "left" }}>
        <label>{labelToDisplay}</label>
        <Dropdown
          disabled={disabled}
          error={error}
          required={required}
          key={label}
          placeholder={placeholder}
          name={label}
          value={value || []}
          fluid
          multiple
          selection
          options={updatedOptions}
          onChange={onChange}
        />
      </div>
    );
  }

  return (
    <Form.Field
      disabled={disabled}
      error={error}
      required={required}
      key={label}
      name={label}
      label={labelToDisplay}
      placeholder={placeholder}
      control={type === "input" ? Input : TextArea}
      value={value}
      onChange={onChange}
      type={inputType || "text"}
    />
  );
};
