/* eslint-disable react/destructuring-assignment, class-methods-use-this */
import React from "react";
import PropTypes from "prop-types";
import {
  Form,
  Button,
  Header,
  Checkbox,
  Container,
  Icon,
  Message,
  Grid,
  Dropdown,
  Input,
} from "semantic-ui-react";
import ContactFormInput from "./components/ContactFormInput";
import axios from "axios";

const quantities = [
  6, 12, 18, 24, 30, 36, 42, 48, 54, 60, 66, 72, 78, 84, 90, 96,
];

const flowers = [
  "French Sorrel",
  "Arugula",
  "Cilantro",
  "Rosemary",
  "Spearmint",
  "Garden Thyme",
  "Basil",
  "Lemon Balm",
  "Italian Parsley",
  "Timotheus's Herb Bundle",
  "Sage",
  "Dill"
];

const unavailable = ["Basil", "Dill"];

const sendContactFormEmail = (params) => {
  const { businessType, businessId, locationId, flowerQuantity, store } =
    params;
  const url = "https://api.gofisherman.com/";
  const fullUrl = `${url}businesses/${businessType}s/${businessId}/locations/${locationId}/email/`;

  const flowersKeys = Object.keys(flowerQuantity);

  const message = flowersKeys
    .map((flower) => `${flower}: ${params.flowerQuantity[flower]}`)
    .join("\n");

  const storeIdMessage = `Order placed for Store #${store}\n\n`;

  return axios.post(fullUrl, {
    contact_name: params.name,
    contact_email: params.email,
    contact_message: storeIdMessage + message,
    email_subject:
      "Someone has reached out to you via your website wholesale form",
  });
};

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.createInitialState();
    this.state.flowerQuantity = {};

    this.onChange = this.onChange.bind(this);
    this.toggleOptIn = this.toggleOptIn.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.clearInputs = this.clearInputs.bind(this);
    this.setInlineMessage = this.setInlineMessage.bind(this);
    this.inputShouldDisplayError = this.inputShouldDisplayError.bind(this);
    this.initializeContactForm = this.initializeContactForm.bind(this);
  }

  onChange(e, { name, value }) {
    const { fields } = this.state;

    this.setInlineMessage("");

    this.setState({
      displayFieldErrors: false,
      fields: { ...fields, [name]: value },
    });
  }

  setInlineMessage(msg, className = null) {
    const { messageTimeout } = this.props;

    this.setState({
      inlineMessage: msg,
      inlineMessageClass: className,
    });

    if (className !== "green" && msg !== "") {
      setTimeout(() => this.setState({ inlineMessage: "" }), messageTimeout);
    }
  }

  toggleOptIn() {
    this.setState((prevState) => {
      const { fields } = prevState;
      return {
        displayFieldErrors: false,
        fields: { ...fields, contact_opt_in: !fields.contact_opt_in },
      };
    });
  }

  createFieldGroups(fields) {
    return fields.reduce(
      (acc, _, i) => (i % 2 ? acc : [...acc, fields.slice(i, i + 2)]),
      []
    );
  }

  resetErrorState() {
    this.setState({
      displayFieldErrors: false,
      loading: false,
    });
  }

  clearInputs() {
    const { fields } = this.props;
    const formFieldState = fields.reduce(
      (acc, { label }) => ({ ...acc, [label]: "" }),
      {}
    );
    this.setState({ fields: formFieldState });
  }

  createInitialState() {
    const { fields, termsCopy } = this.props;

    const formFieldState = fields.reduce(
      (acc, { label, value }) => ({ ...acc, [label]: value }),
      {}
    );
    const additionalState = {
      inlineMessageClass: "",
      inlineMessage: "",
      displayFieldErrors: false,
      loading: false,
    };
    const acceptTermsState = termsCopy ? { acceptedTerms: false } : {};
    return {
      fields: { ...formFieldState },
      ...additionalState,
      ...acceptTermsState,
    };
  }

  initializeContactForm() {
    const initState = this.createInitialState();
    this.setState(initState);
  }

  areAllRequiredFieldsFilled() {
    const { fields } = this.state;

    const { fields: fieldsProps } = this.props;
    return (
      fieldsProps.filter((field) => field.required && !fields[field.label])
        .length === 0
    );
  }

  inputShouldDisplayError(field) {
    const { displayFieldErrors, fields } = this.state;
    return field.required && displayFieldErrors && !fields[field.label];
  }

  handleSubmit() {
    const {
      onSubmit,
      onSubmitSuccess,
      onSubmitError,
      locationId,
      businessId,
      businessType,
      emailSubject,
    } = this.props;

    if (this.areAllRequiredFieldsFilled()) {
      this.setState({ loading: true }, () => {
        const onSubmitParams = {
          businessType,
          businessId,
          locationId,
          ...this.state.fields,
          flowerQuantity: this.state.flowerQuantity,
        };

        onSubmit(onSubmitParams)
          .then(() => {
            this.clearInputs();
            this.setInlineMessage("Successfully sent!", "green");
            this.resetErrorState();

            if (onSubmitSuccess) {
              onSubmitSuccess();
            }
          })
          .catch(() => {
            if (onSubmitError) {
              onSubmitError();
            }
            this.setInlineMessage(
              "Unknown error sending contact form. Please try again later.",
              "red"
            );
            this.resetErrorState();
          });
      });
    } else {
      this.setInlineMessage("Please fill in all required fields!", "red");
      this.setState({
        displayFieldErrors: true,
        loading: false,
      });
    }
  }

  mapFieldsToInputGroup(fields) {
    const { showLabels } = this.props;
    const { fields: allFields } = this.state;

    return (
      <Form.Group widths="equal" key={fields[0].label}>
        {fields.map((field) => (
          <ContactFormInput
            showLabels={showLabels}
            field={field}
            value={allFields[field.label]}
            onChange={this.onChange}
            error={this.inputShouldDisplayError(field)}
          />
        ))}
      </Form.Group>
    );
  }

  renderForms(disabledInputs = false) {
    const { fields, inline, showLabels } = this.props;
    const { fields: allFields } = this.state;

    // Create chunks of two forms per group
    const fieldGroups = this.createFieldGroups(fields);

    // Wrap fields with form components
    const formGroups = fieldGroups.map((fieldGroup) =>
      this.mapFieldsToInputGroup(fieldGroup)
    );

    const forms = fields.map((field) => (
      <ContactFormInput
        showLabels={showLabels}
        field={field}
        value={allFields[field.label]}
        disabled={disabledInputs}
        onChange={this.onChange}
        error={this.inputShouldDisplayError(field)}
      />
    ));

    const fieldsWithForms = inline ? formGroups : forms;

    return (
      <React.Fragment>
        {fieldsWithForms}

        {/* <Form.Field disabled={disabledInputs}>
          <Checkbox
            name="contact_opt_in"
            label={
              "By checking this box I have read, understand, and agree to the disclaimer above."
            }
            onChange={this.toggleOptIn}
            checked={allFields.contact_opt_in}
          />
          
        </Form.Field> */}
      </React.Fragment>
    );
  }

  handleQuantityChange = (flower, quantity) => {
    let flowerQuantity = {};
    flowerQuantity = { ...this.state.flowerQuantity };
    flowerQuantity[flower] = quantity;
    this.setState({ flowerQuantity });
  };

  renderTOS() {
    const { termsCopy } = this.props;
    const { acceptTerms } = this.state;

    return (
      termsCopy && (
        <Form.Field
          control={Checkbox}
          label={termsCopy}
          value={acceptTerms}
          onChange={this.onChange}
        />
      )
    );
  }

  renderHeader() {
    const { header, subheader, centeredHeaders } = this.props;

    return (
      <React.Fragment>
        {header && (
          <Header as="h1" textAlign={centeredHeaders ? "center" : "left"}>
            {header}
          </Header>
        )}
        {subheader && (
          <Header as="h3" textAlign={centeredHeaders ? "center" : "left"}>
            {subheader}
          </Header>
        )}
      </React.Fragment>
    );
  }

  render() {
    const { buttonText, className, fluidButton, withContainer, button } =
      this.props;
    const { loading, inlineMessage, inlineMessageClass } = this.state;
    const isSuccessMsg = inlineMessageClass === "green";

    const quantityOptions = quantities.map((quantity, index) => ({
      key: quantity,
      text: quantity,
      value: quantity,
    }));

    const form = (
      <div className={className}>
        <Form loading={loading} success={isSuccessMsg} error={!isSuccessMsg}>
          {this.renderHeader()}
          {this.renderForms(isSuccessMsg)}
          {this.renderTOS()}

          {flowers.map((flower) => (
            <Grid columns="equal">
              <Grid.Row>
                <Grid.Column>{flower}</Grid.Column>
                <Grid.Column>
                  <Dropdown
                    disabled={unavailable.includes(flower) ? true : false}
                    placeholder={
                      unavailable.includes(flower) ? "Unavailable" : "Quantity"
                    }
                    options={quantityOptions}
                    onChange={(e, data) =>
                      this.handleQuantityChange(flower, data.value)
                    }
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          ))}
          <br />
          <div>
            {inlineMessage && (
              <Message success={isSuccessMsg} negative={!isSuccessMsg}>
                <Message.Content>
                  {inlineMessage}
                  {isSuccessMsg && (
                    <>
                      {" "}
                      <Icon name="check circle outline" />
                    </>
                  )}
                </Message.Content>
              </Message>
            )}
            <Button onClick={this.handleSubmit} primary fluid={fluidButton}>
              {buttonText}
            </Button>
          </div>
        </Form>
      </div>
    );

    return withContainer ? <Container>{form}</Container> : { form };
  }
}

ContactForm.propTypes = {
  locationId: PropTypes.number.isRequired,
  businessId: PropTypes.number.isRequired,
  businessType: PropTypes.string.isRequired,
  emailSubject: PropTypes.string,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      placeholder: PropTypes.string,
      value: PropTypes.string,
      type: PropTypes.oneOf([
        "input",
        "textarea",
        "select",
        "hidden",
        "multiSelect",
      ]),
      inputType: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        })
      ),
    })
  ),
  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitError: PropTypes.func,
  messageTimeout: PropTypes.number,
  buttonText: PropTypes.string,
  centeredHeaders: PropTypes.bool,
  className: PropTypes.string,
  fluidButton: PropTypes.bool,
  button: PropTypes.bool,
  header: PropTypes.string,
  inline: PropTypes.bool,
  showLabels: PropTypes.bool,
  subheader: PropTypes.string,
  termsCopy: PropTypes.string,
  withContainer: PropTypes.bool,
};

ContactForm.defaultProps = {
  emailSubject: "",
  fields: [
    {
      label: "name",
      placeholder: "Name",
      type: "input",
      required: true,
    },
    {
      label: "email",
      placeholder: "you@gmail.com",
      type: "input",
      inputType: "email",
      required: true,
    },
    {
      label: "message",
      placeholder: "Message",
      type: "textarea",
      required: false,
    },
  ],
  onSubmit: sendContactFormEmail,
  onSubmitSuccess: null,
  onSubmitError: null,
  messageTimeout: 10000,
  buttonText: "Submit",
  centeredHeaders: false,
  className: "",
  fluidButton: false,
  button: true,
  header: "",
  inline: false,
  showLabels: false,
  subheader: "",
  termsCopy: "",
  withContainer: true,
};

ContactForm.propOptions = {
  header: ["Contact Form", "Contact Us"],
  termsCopy: ["Accept the Terms of Service", ""],
};

ContactForm.dataPropsMap = {};
