import React from "react";
import { graphql } from "gatsby";
import { Grid, Input, Form, Dropdown } from "semantic-ui-react";
import Helmet from "react-helmet";
import Markdown from "markdown-to-jsx";
import {
  PageLayout,
  CardNavigation,
  HeroElement,
  Contact,
} from "@fishrmn/fishrmn-components";
import emailCallback from "../utils/email.js";
import ContactForm from "../components/ContactForm/ContactForm";

import AWS from "aws-sdk";

import Layout from "../components/Layout";
import { getPageHeroImages, getPageGalleryImages } from "../utils/images";

export default class extends React.PureComponent {
  render() {
    const data = this.props.data.allFisherman.edges[0].node;
    const pageData = this.props.data.fishermanWebsitePage;
    const pageComponents = (pageData && pageData.components) || [];

    return (
      <Layout>
        <Helmet>
          <title>{data.businessName} | Contact</title>
          <meta name="description" content={pageData.description} />
        </Helmet>
        <PageLayout
          hero={
            <HeroElement
              header={"Wholesale"}
              tagline={""}
              images={getPageHeroImages(pageComponents)}
              showMap={false}
              ctas={[]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
              gutter={false}
            />
          }
        >
          <Grid
            stackable
            className="component-section-container"
            verticalAlign={"middle"}
            centered
          >
            <Grid.Column width={16} textAlign="center">
              <h3>
                OPHELIA’S BLUE VINE FARM DELIVERS WHOLESALE ORDERS PLACED SAME
                DAY OR NEXT DAY DELIVERY!
              </h3>
              <h1>Wholesale Orders</h1>
            </Grid.Column>
            <Grid.Column width={6} textAlign="center">
              <ContactForm
                businessId={3488}
                businessType="restaurant"
                locationId={3293}
                buttonText={"Place Order"}
                centeredHeaders={true}
                className={""}
                fluidButton={false}
                header={""}
                inline={false}
                showLabels={true}
                subheader={""}
                termsCopy={""}
                withContainer={true}
                fields={[
                  {
                    label: "name",
                    labelDisplay: "Name",
                    placeholder: "Name",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "email",
                    labelDisplay: "Email",
                    placeholder: "you@gmail.com",
                    type: "input",
                    inputType: "email",
                    required: true,
                  },
                  {
                    label: "store",
                    labelDisplay: "Store #",
                    placeholder: "Store #",
                    type: "input",
                    inputType: "text",
                    required: true,
                  },
                ]}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          ctas {
            address {
              link
              text
              internal
            }
          }
          businessName
          phone
          email
          address {
            street
            city
            state
            zip
          }
          hours {
            day
          }
        }
      }
    }
    fishermanWebsitePage(pageType: { eq: "Contact" }) {
      description
      components {
        fastId
        order
        files {
          file
        }
      }
    }
  }
`;
